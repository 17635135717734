<template>
  <div class="whole">
    <Nav></Nav>
    <!-- <videoNav v-else></videoNav> -->
    <div class="registerAll">
      <div class="head">
        <img src="" alt="" />
        您的位置：<span>首页 </span> >> 新用户注册
      </div>
      <div class="content">
        <div class="title">
          欢迎您注册 “美美吴江”, 一旦注册，用户真实姓名和登录名将不能更改！
        </div>
        <div class="bindMobile" v-if="pageStep == 1">
          <!-- <div class="bindMobile"> -->
          <div class="mobile-con">
            <!-- <div class="mobile-c">
              <span>手机号：</span>
              <el-input
                v-model="input"
                placeholder="请输入手机号"
                clearable
                @blur="onsetMobile"
              ></el-input>
              <el-button @click="onCaptcha" v-if="requestSMS"
                >获取验证码</el-button
              >
              <el-button v-else>{{ requestSMSTimeout }}秒后重发</el-button>
            </div>
            <div class="mobileback">
              <span>验证码：</span>
              <el-input
                v-model="identifyingCode"
                placeholder="请输入验证码"
                clearable
              ></el-input>
            </div> -->
            <div class="input-line">
              <span>手机号：</span>
              <el-input
                v-model="input"
                placeholder="请输入手机号"
                clearable
                @blur="onsetMobile"
              ></el-input>
            </div>
            <div class="input-line">
              <span>图形验证码：</span>
              <el-input
                v-model="inputIcode"
                placeholder="请输入图形验证码"
                clearable
              ></el-input
              ><img :src="icodeImage_URL" @click="onLoad" />
            </div>
            <div class="input-line">
              <span>短信验证码：</span>
              <el-input
                v-model="identifyingCode"
                placeholder="请输入短信验证码"
                clearable
              ></el-input>
              <el-button @click="onCaptcha" v-if="numberChange"
                >获取验证短信</el-button
              >
              <el-button v-else>{{ numberText }}秒后重发</el-button>
            </div>
            <div class="step">
              <el-button @click="onNext">下一步</el-button>
            </div>
          </div>
        </div>
        <div class="register-con" v-if="pageStep == 2">
          <!-- <div class="register-con"> -->
          <div class="input-line">
            <span>登录名：</span>
            <el-input
              v-model="loginName"
              v-bind:class="{ redBorder: loginNameShow }"
              placeholder="请输入内容"
              @blur="onlogoName"
              clearable
            ></el-input>
            <i>*必须为字母或者数字及其组合</i>
          </div>
          <div class="input-line">
            <span>真实姓名：</span>
            <el-input
              v-model="realName"
              placeholder="请输入内容"
              clearable
            ></el-input>
            <i>*请填写真实姓名否则会影响注册</i>
          </div>
          <div class="input-line">
            <span>注册类型：</span>
            <el-select v-model="userType" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
          <div class="input-line">
            <span>地址：</span>
            <el-input
              v-model="address"
              placeholder="请输入内容"
              clearable
            ></el-input>
          </div>
          <div class="input-line">
            <span>密码：</span>
            <el-input
              v-model="password"
              placeholder="请输入内容"
              show-password
              @blur="onpassword"
              clearable
            ></el-input>
            <i>*密码应至少8位，包含数字，大写和小写之中的至少两种</i>
          </div>
          <div class="input-line">
            <span>确认密码：</span>
            <el-input
              v-model="repw"
              placeholder="请输入内容"
              show-password
              clearable
            ></el-input>
            <i
              >*合法密码字符如下：~ ! @ # $ % ^ & * ( ) _ + - =
              ，除此以外为非法字符</i
            >
          </div>
          <!-- <div class="iphonpe">
            <span>手机：</span>
            <el-input
              v-model="input"
              placeholder="请输入手机号"
              clearable
              @blur="onsetMobile"
            ></el-input>
            <el-button @click="onCaptcha">获取验证码</el-button>
            <i>*填写手机号码和验证码后点击“获取验证码”获取验证短信</i>
          </div>
          <div class="mobile-yan">
            <span>短信验证码：</span>
            <el-input
              v-model="identifyingCode"
              placeholder="请输入验证码"
              clearable
            ></el-input>
          </div> -->

          <!-- <div class="input-line">
            <span>单位：</span>
            <el-input
              v-model="company"
              placeholder="请输入工作单位"
              clearable
            ></el-input>
          </div> -->
          <!-- <div class="input-line">
            <span>职务：</span>
            <el-input
              v-model="zhiwu"
              placeholder="请输入职务"
              clearable
            ></el-input>
          </div> -->

          <!-- <div class="input-line">
            <span>邮编：</span>
            <el-input
              v-bind:class="{ redBorder: postShow }"
              v-model="postCode"
              placeholder="请输入编码"
              @blur="onpostCode"
              clearable
            ></el-input>
          </div> -->
          <!-- <div class="input-line">
            <span>固定电话：</span>
            <el-input
              v-model="telephone"
              placeholder="请输入固定电话"
              clearable
            ></el-input>
          </div> -->
          <!-- <div class="input-line">
            <span>Email：</span>
            <el-input
              v-model="email"
              placeholder="请输入email"
              clearable
            ></el-input>
          </div> -->
          <!-- <div class="note">
            <span>备注：</span>
            <el-input
              type="textarea"
              :rows="8"
              placeholder="请输入内容"
              v-model="comment"
              @input="handleCommentChange"
            >
            </el-input>
            <span>（{{ commmentLength }}字）</span>
          </div> -->

          <div class="btnsure">
            <el-button @click="onEnsure" type="success">完成注册</el-button>
          </div>
        </div>
      </div>
    </div>

    <Bottom></Bottom>
    <cutPic
      class="cutCanvs"
      v-if="cutshadow"
      @editFinished="editFinished"
      :imgInfo="cutImg"
      @wheel.prevent="doNothing"
    >
    </cutPic>

    <cutCard
      class="cutCardCanvs"
      v-if="cutCardShow"
      @editFinishedCard="editFinishedCard"
      :idCardInfo="cutCard"
    >
    </cutCard>
  </div>
</template>

<script>
import RDUtils from "@/api/RDUtils";
import RD from "@/api/RD";
import SHA1 from "js-sha1";
import cutPic from "@/views/cutHead.vue";
import cutCard from "@/views/cutIdCard.vue";
import Nav from "@/components/nav.vue";
// import videoNav from "@/components/videoNav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom, cutPic, cutCard },
  data: function () {
    return {
      options: [
        { value: "注册摄影师", label: 1 },
        { value: "融媒记者", label: 3 },
        { value: "下载用户", label: 2 },
      ],
      inputIcode: "",
      icodeImage_URL: "",
      identifyingCode: "", // 输入的验证码
      returnIdentifyingcode: "", // 手机返回来的验证码
      input: "",
      loginName: "",
      realName: "",
      IdCard: "", // 身份证号
      IdCardFront: "", // 正面
      IdCardBack: "", // 反面
      password: "",
      address: "",
      company: "",
      email: "",
      postCode: "",
      head: "",
      repw: "",
      reward: "", // 奖项
      telephone: "",
      sheji: "",
      xinwen: "",
      sheying: "",
      bankAccont: "",
      bank: "",
      comment: "", // 个人简介
      zhiwu: "",
      weixin: "",
      qq: "",
      newsPaper: "",
      newWork: "",
      weChat: "",
      client: "",
      zazhi: "",
      book: "",
      other: "",
      areaCode: "", // 固定电话区号
      maintelephone: "", // 固定电话的主号码
      fentelephone: "", // 分机号
      roleShow: false,
      registed: false, // 是否注册
      erShow: false, // 第二步注册
      threeShow: false, // 第三步注册
      userType: 1,
      representativeImgURLOne: "", // 代表作1的地址
      topPics: [], // 代表作数组
      imgUploadURL: "", // 上传身份证正面的地址
      imgUploadURLback: "",
      headImage: "", // 上传的路径
      headImg: "", // 保存的上传参数
      idFront: "", // 上传身份证正面的路径
      idBack: "", // 上传身份证反面的路径
      imgURL: "", //上传注册用户头像之后的展示
      idImgURL: "", // 上传注册用户身份证正面之后的展示
      idImgURLBack: "", // 上传注册用户身份证反面之后的展示
      sheyingList: ["国家级", "省级", "区市级"],
      xinwenList: ["国家级", "省级", "区市级"],
      shejiList: ["国家级", "省级", "区市级"],
      rewardList: ["国家级", "省级", "区市级"],
      daili: 3, // 注册的代理权限
      exclusive: false,
      navFlag: 1,
      numberChange: true, // 验证码的显示
      numberText: 50,
      xieyiShow: false, // 协议的展示
      cutshadow: false, // 切图的显示
      cutCardShow: false, // 切身份证的显示
      cutImg: {}, // 切头像上传的数据
      cutCard: {}, // 切身份证
      xieyiBtnShow: true, // 协议的按钮是否可点
      postShow: false, // 编码不合法时的提示
      emailShow: false, // 邮箱不合法时的提示
      qqShow: false, // qq不合法的提示
      weixinShow: false, //
      telephoneShow: false, //
      bankAccShow: false, //
      idcardShow: false, //
      loginNameShow: false, //
      commmentLength: 0,
      pageStep: 1,
      requestSMS: true, // 验证码的显示
      requestSMSTimeout: 50,
    };
  },
  created() {
    var thiz = this;
    thiz.headImage = RD.user().uploadPicUnregisted_URL();
    thiz.icodeImage_URL =
      RD.user().icodeImage_URL() + "?_" + new Date().getTime();
    if (this.$route.query.id == 1) {
      thiz.navFlag = 1;
    } else {
      thiz.navFlag = 2;
    }
    if (thiz.$route.query.type == "buy") {
      thiz.userType = 2;
    }
  },
  methods: {
    onGONext() {
      // this.xieyiShow = true;
      this.roleShow = false;
      this.registed = true;
    },
    gotoScroll(e) {
      var scorllheight = e.srcElement.scrollHeight - 200;
      if (e.srcElement.scrollTop + e.srcElement.offsetHeight >= scorllheight) {
        console.log("到底了");
        this.xieyiBtnShow = true;
      }
    },
    onClose() {
      this.xieyiShow = false;
      this.xieyiBtnShow = false;
    },
    // 第一步验证
    ongotwo() {
      this.xieyiShow = false;
      this.roleShow = false;
      this.registed = true;
      this.xieyiShow = false;
    },
    // 是否是独家代理用户
    onChangeExclusive(value) {
      console.log("mx", value);
      var thiz = this;
      if (thiz.daili == 3) {
        thiz.exclusive = true;
      } else {
        thiz.exclusive = false;
      }
    },
    // 验证邮箱
    onSendEmail() {
      var thiz = this;
      var regEmail =
        /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
      if (thiz.email != "" && !regEmail.test(thiz.email)) {
        this.$message.error({
          message: "邮箱格式不正确",
          type: "warning",
        });
        thiz.emailShow = true;
      } else {
        thiz.emailShow = false;
      }
    },
    // 验证QQ
    onSendQQ() {
      var thiz = this;
      var regqq = /^[1-9][0-9]{4,10}$/;
      if (thiz.qq != "" && !regqq.test(thiz.qq)) {
        this.$message.error({
          message: "qq格式不正确",
          type: "warning",
        });
        thiz.qqShow = true;
        return;
      } else {
        thiz.qqShow = false;
      }
    },
    // 验证微信
    onweixin() {
      var thiz = this;
      var regweiixn = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/;
      if (thiz.weixin != "" && !regweiixn.test(thiz.weixin)) {
        this.$message.error({
          message: "微信格式不正确",
          type: "warning",
        });
        thiz.weixinShow = true;
        return;
      } else {
        thiz.weixinShow = false;
      }
    },
    // 区号
    onareaCode() {
      var thiz = this;
      if (isNaN(parseInt(thiz.areaCode)) === true) {
        this.$message.error({
          message: "请输入数字区号",
          type: "warning",
        });
        thiz.areaCode = "";
        return;
      }
    },
    // 固定电话
    ontelephone() {
      var thiz = this;
      var telmain = /^([0-9]{1})(\d{5,})$/;
      if (thiz.maintelephone != "" && !telmain.test(thiz.maintelephone)) {
        thiz.$message.error({
          message: "请至少输入6位及以上数字号码",
          type: "warning",
        });
        thiz.telephoneShow = true;
      } else {
        thiz.telephoneShow = false;
      }
    },
    // 验证银行账户
    onSendBank() {
      var thiz = this;
      var regBank = /^([1-9]{1})(\d{11,18})$/;
      if (thiz.bankAccont != "" && !regBank.test(thiz.bankAccont)) {
        this.$message.error({
          message: "银行账户格式不正确",
          type: "warning",
        });
        thiz.bankAccShow = true;
        return;
      } else {
        thiz.bankAccShow = false;
      }
    },
    // 身份证号验证
    onSendIdcard() {
      var thiz = this;
      var regIdcard =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      if (thiz.IdCard != "" && !regIdcard.test(thiz.IdCard)) {
        this.$message.error({
          message: "身份证格式不正确",
          type: "warning",
        });
        thiz.idcardShow = true;
        return;
      } else {
        thiz.idcardShow = false;
      }
    },
    // 验证手机号
    onsetMobile() {
      var thiz = this;
      var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (thiz.input != "" && !mobile.test(thiz.input)) {
        this.$alert("手机号不合法, 请重新填写", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
      }
    },
    onLoad() {
      var thiz = this;
      thiz.icodeImage_URL =
        RD.user().icodeImage_URL() + "?" + new Date().getTime();
    },
    // 获取验证码
    onCaptcha() {
      var thiz = this;
      if (thiz.input == "") {
        thiz.$alert("请填写注册手机号");
        return;
      }
      var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (thiz.input != "" && !mobile.test(thiz.input)) {
        this.$alert("手机号不合法, 请重新填写", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
      }
      if (thiz.inputIcode == "") {
        thiz.$alert("请先填写图形验证码");
        return;
      }

      RD.pure()
        .user()
        .requestSMSForRegister(thiz.inputIcode, thiz.input)
        .then(() => {
          thiz.requestSMS = false;
          setInterval(() => {
            thiz.requestSMSTimeout--;
            if (thiz.requestSMSTimeout == 0) {
              thiz.requestSMS = true;
              thiz.requestSMSTimeout = 50;
            }
          }, 1000);
          thiz.$message("手机发送验证码成功，请注意查收");
        })
        .catch((error) => {
          this.$alert(error.msg, "提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
        });
    },
    // 下一步
    onNext() {
      var thiz = this;
      if (thiz.input == "" || thiz.input == undefined) {
        thiz.$message("请先填写手机号");
        return;
      }
      if (thiz.identifyingCode == "" || thiz.identifyingCode == undefined) {
        thiz.$message("请先填写手机验证码");
        return;
      }
      RD.user()
        .confirmCodeForRegist(thiz.identifyingCode)
        .then(() => {
          thiz.pageStep = 2;
        });
    },
    // 正则验证登录名
    onlogoName() {
      var thiz = this;
      var name = /^[a-zA-Z0-9_-]{4,16}$/;
      if (thiz.loginName != "" && !name.test(thiz.loginName)) {
        thiz.$message.error({
          message: "登录名不合法, 请重新填写",
          type: "warning",
        });
        thiz.loginNameShow = true;
        return;
      } else {
        thiz.loginNameShow = false;
      }
    },
    // 邮编
    onpostCode() {
      var thiz = this;
      var code =
        /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/;
      if (thiz.postCode != "" && !code.test(thiz.postCode)) {
        thiz.$message.error({
          message: "邮编不合法, 请重新填写",
          type: "warning",
        });
        thiz.postShow = true;
      } else {
        thiz.postShow = false;
      }
    },
    // 验证密码
    onpassword() {
      var thiz = this;
      if (thiz.password != "" && !RDUtils.checkWeekPassword(thiz.password)) {
        alert("新密码应至少8位，包含数字，大写和小写之中的至少两种");
        return;
      }
    },
    // 确认
    async onEnsure() {
      var thiz = this;
      // var regEmail =
      //   /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
      // if (thiz.email != "" && !regEmail.test(thiz.email)) {
      //   this.$message.error({
      //     message: "邮箱格式不正确",
      //     type: "warning",
      //   });
      //   thiz.emailShow = true;
      //   return;
      // } else {
      //   thiz.emailShow = false;
      // }

      // var code =
      //   /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/;
      // if (thiz.postCode != "" && !code.test(thiz.postCode)) {
      //   thiz.$message.error({
      //     message: "邮编不合法, 请重新填写",
      //     type: "warning",
      //   });
      //   thiz.postShow = true;
      //   return;
      // } else {
      //   thiz.postShow = false;
      // }

      var name = /^[a-zA-Z0-9_-]{4,16}$/;
      if (thiz.loginName != "" && !name.test(thiz.loginName)) {
        thiz.$message.error({
          message: "登录名不合法, 请重新填写",
          type: "warning",
        });
        thiz.loginNameShow = true;
        return;
      } else {
        thiz.loginNameShow = false;
      }

      // var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      // if (thiz.input != "" && !mobile.test(thiz.input)) {
      //   thiz.$message("手机号不合法, 请重新填写");
      //   return;
      // }

      // var regIdcard =
      //   /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      // if (thiz.IdCard != "" && !regIdcard.test(thiz.IdCard)) {
      //   this.$message.error({
      //     message: "身份证格式不正确",
      //     type: "warning",
      //   });
      //   thiz.idcardShow = true;
      //   return;
      // } else {
      //   thiz.idcardShow = false;
      // }

      if (thiz.password != "" && !RDUtils.checkWeekPassword(thiz.password)) {
        alert("新密码应至少8位，包含数字，大写和小写之中的至少两种");
        return;
      }

      // var regweiixn = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/;
      // if (thiz.weixin != "" && !regweiixn.test(thiz.weixin)) {
      //   this.$message.error({
      //     message: "微信格式不正确",
      //     type: "warning",
      //   });
      //   thiz.weixinShow = true;
      //   return;
      // } else {
      //   thiz.weixinShow = false;
      // }

      // var regqq = /^[1-9][0-9]{4,10}$/;
      // if (thiz.qq != "" && !regqq.test(thiz.qq)) {
      //   this.$message.error({
      //     message: "qq格式不正确",
      //     type: "warning",
      //   });
      //   thiz.qqShow = true;
      //   return;
      // } else {
      //   thiz.qqShow = false;
      // }

      // if (thiz.areaCode != "" && isNaN(parseInt(thiz.areaCode)) === true) {
      //   this.$message.error({
      //     message: "请输入数字区号",
      //     type: "warning",
      //   });
      //   thiz.areaCode = "";
      //   return;
      // }

      // var telmain = /^([0-9]{1})(\d{5,})$/;
      // if (thiz.maintelephone != "" && !telmain.test(thiz.maintelephone)) {
      //   thiz.$message.error({
      //     message: "请至少输入6位及以上数字号码",
      //     type: "warning",
      //   });
      //   thiz.telephoneShow = true;
      // } else {
      //   thiz.telephoneShow = false;
      // }

      if (thiz.loginName == "" || thiz.loginName == undefined) {
        thiz.$message("登录名不能为空");
        return;
      }
      if (thiz.realName == "" || thiz.realName == undefined) {
        thiz.$message("真实姓名不能为空");
        return;
      }

      // if (thiz.IdCard == "" || thiz.IdCard == undefined) {
      //   thiz.$message("身份证号不能为空");
      //   return;
      // }

      // if (thiz.headImage == "" || thiz.headImage == undefined) {
      //   thiz.$message("请上传身份证正面");
      //   return;
      // }

      if (thiz.address == "" || thiz.address == undefined) {
        thiz.$message("地址不能为空");
        return;
      }
      // if (thiz.postCode == "" || thiz.postCode == undefined) {
      //   thiz.$message("邮编不能为空");
      //   return;
      // }
      // if (thiz.email == "" || thiz.email == undefined) {
      //   thiz.$message("邮箱不能为空");
      //   return;
      // }

      // if (thiz.company == "" || thiz.company == undefined) {
      //   thiz.$message("工作单位不能为空");
      //   return;
      // }

      // if (thiz.idFront == "" || thiz.idFront == undefined) {
      //   thiz.$message("身份证正面不能为空");
      //   return;
      // }

      // if (thiz.idBack == "" || thiz.idBack == undefined) {
      //   thiz.$message("身份证反面不能为空");
      //   return;
      // }

      // if (thiz.zhiwu == "" || thiz.zhiwu == undefined) {
      //   thiz.$message("职务不能为空");
      //   return;
      // }

      var checkname = await RD.user().checkChnName(thiz.loginName);
      if (checkname) {
        thiz.erShow = true;
        thiz.registed = false;
      }

      var checkloginName = RD.user().checkLoginName(thiz.realName);
      if (checkloginName) {
        thiz.erShow = true;
        thiz.registed = false;
      }

      if (thiz.password != thiz.repw) {
        thiz.$message("两次输入的密码不一致，请重新输入");
        return;
      }
      if (thiz.password == "" || thiz.password == undefined) {
        thiz.$message.error({ message: "密码不能为空", type: "warning" });
        return;
      }

      // if (thiz.topPics.length < 1) {
      //   thiz.$message.error({ message: "代表作至少上传一个", type: "warning" });
      //   return;
      // }

      var topPicArrs = [];
      thiz.topPics.forEach((k) => {
        var topPics = {};
        topPics.uuid = k.uuid;
        topPics.text = k.text;
        topPicArrs.push(topPics);
      });

      var address = thiz.address;
      var bank = thiz.bank;
      var bankAcc = thiz.bankAccont;
      var comment = thiz.comment;
      var company = thiz.company;
      var email = thiz.email;
      var exclusive = thiz.exclusive;
      var zhiwu = thiz.zhiwu;
      var headImg = thiz.headImg;
      var idBack = thiz.idBack;
      var idFront = thiz.idFront;
      var IdCard = thiz.IdCard;
      var postCode = thiz.postCode;
      var qq = thiz.qq;
      var sheji = thiz.sheji;
      var sheying = thiz.sheying;
      var telephone = thiz.telephone;
      var reward = thiz.reward;
      var weixin = thiz.weixin;
      var xinwen = thiz.xinwen;
      var extra = {
        address,
        bank,
        bankAcc,
        comment,
        company,
        email,
        exclusive,
        zhiwu,
        headImg,
        idBack,
        idFront,
        IdCard,
        postCode,
        qq,
        sheji,
        sheying,
        telephone,
        topPicArrs,
        reward,
        weixin,
        xinwen,
      };

      // console.log("topicsarr", topPicArrs);
      if (thiz.userType == 1 || thiz.userType == 3) {
        var roleId = 1;

        if (thiz.userType == 1) {
          roleId = 21;
        } else if (thiz.userType == 3) {
          roleId = 24;
        }

        RD.user()
          .registerUploader(
            thiz.loginName,
            SHA1(thiz.password),
            thiz.realName,
            extra,
            roleId
          )
          .then(() => {
            thiz.$message("注册上传者角色成功,等待审批后方可上传");
            this.$router.push("/index.html");
          })
          .catch((error) => {
            thiz.$message(error);
          });
      } else {
        var roleId = 31;
        RD.user()
          .registerDownloader(
            thiz.loginName,
            SHA1(thiz.password),
            thiz.realName,
            extra,
            roleId
          )
          .then((info) => {
            console.log("kl", info);
            thiz.$message("注册下载者角色成功,等待审批后方可上传");
            thiz.$router.push("/index.html");
          });
      }
    },
    // 上传头像成功后的处理
    onGetheadImage(res, file) {
      console.log("head", res);
      var thiz = this;
      if (res.code == 0) {
        thiz.imgURL = URL.createObjectURL(file.raw);
        thiz.headImg = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传身份证正面成功后的处理
    onSuccessFront(res, file) {
      console.log("zheng", res);
      if (res.code == 0) {
        this.idImgURL = URL.createObjectURL(file.raw);
        this.idFront = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传身份证反面成功后的处理
    onSuccessBack(res, file) {
      var thiz = this;
      console.log("fan", res);
      if (res.code == 0) {
        thiz.idImgURLBack = URL.createObjectURL(file.raw);
        thiz.idBack = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传代表作
    onSuccessRepresentative(res, file) {
      var thiz = this;
      console.log("res", res);
      if (res.code == 0) {
        var top = {};
        top.imgURL = URL.createObjectURL(file.raw);
        top.uuid = res.data;
        thiz.topPics.push(top);
        console.log("topics", thiz.topPics);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 删除代表作
    ondelTopPics(index) {
      console.log("ss", index);
      this.topPics.splice(index, 1);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 切头像
    onCutHead() {
      if (this.imgURL) {
        this.cutshadow = true;
        this.cutImg.src =
          RD.user().unregistedImageGet_URL(this.headImg) +
          "?_=" +
          new Date().getTime();
        this.cutImg.width = window.innerWidth * 0.5;
        this.cutImg.height = window.innerHeight * 0.5;
      } else {
        this.$message("请先上传要剪切的头像");
      }
    },
    // 打开切头像的弹框
    editFinished(value, arrs) {
      console.log("cut", value);
      console.log("cutarrs", arrs);

      if (value == 2) {
        this.cutshadow = false;
      }

      if (arrs != undefined) {
        RD.user()
          .uploadPicUnregistedCut(
            this.headImg,
            arrs.x1,
            arrs.y1,
            arrs.x2,
            arrs.y2
          )
          .then(() => {
            this.$message("剪切图片成功");
            this.imgURL =
              RD.user().unregistedImageGet_URL(this.headImg) +
              "?_=" +
              new Date().getTime();
          });
      }
    },
    // 剪切身份证
    onCutIdCard(type) {
      if (type == "front") {
        if (this.idImgURL) {
          this.cutCardShow = true;
          this.cutCard.src =
            RD.user().unregistedImageGet_URL(this.idFront) +
            "?_=" +
            new Date().getTime();
          this.cutCard.width = window.innerWidth * 0.5;
          this.cutCard.height = window.innerHeight * 0.5;
          this.cutCard.type = "front";
        } else {
          this.$message("请先上传身份证正面");
        }
      } else {
        if (this.idImgURLBack) {
          this.cutCardShow = true;
          this.cutCard.src =
            RD.user().unregistedImageGet_URL(this.idBack) +
            "?_=" +
            new Date().getTime();
          this.cutCard.width = window.innerWidth * 0.5;
          this.cutCard.height = window.innerHeight * 0.5;
          this.cutCard.type = "back";
        } else {
          this.$message("请先上传身份证反面");
        }
      }

      console.log("shs", this.cutCard);
    },
    // 切身份证的返回
    editFinishedCard(value, arrsCard, cutType) {
      console.log("cut", value);
      console.log("cutarrs", arrsCard);

      if (value == 2) {
        this.cutCardShow = false;
      }

      if (arrsCard != undefined) {
        if (cutType == 1) {
          RD.user()
            .uploadPicUnregistedCut(
              this.idFront,
              arrsCard.x1,
              arrsCard.y1,
              arrsCard.x2,
              arrsCard.y2
            )
            .then(() => {
              this.$message("剪切身份证成功");
              this.idImgURL =
                RD.user().unregistedImageGet_URL(this.idFront) +
                "?_=" +
                new Date().getTime();
            });
        } else {
          console.log("back");
          RD.user()
            .uploadPicUnregistedCut(
              this.idBack,
              arrsCard.x1,
              arrsCard.y1,
              arrsCard.x2,
              arrsCard.y2
            )
            .then(() => {
              this.$message("剪切身份证成功");
              this.idImgURLBack =
                RD.user().unregistedImageGet_URL(this.idBack) +
                "?_=" +
                new Date().getTime();
            });
        }
      }
    },
    // 检测简介的文字数量
    handleCommentChange() {
      var thiz = this;
      thiz.commmentLength = this.comment.length;
    },
  },
};
</script>

<style scoped lang="less">
.whole {
  height: auto;
}
.cutCanvs {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}
.cutCardCanvs {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}
.registerAll {
  height: auto;
  min-height: calc(100vh - 47.89rem);
  width: 120rem;
  margin: 0 auto;
  .head {
    height: 40px;
    background-color: #dfdfdf;
    line-height: 40px;
    white-space: nowrap;
    text-align: left;
    clear: both;
    display: flex;
    font-size: 14px;
    img {
      width: 15px;
    }
    span {
      color: red;
    }
  }
  .content {
    width: 100%;
    background-color: #efefef;
    .title {
      font-weight: bold;
      font-size: 18px;
      margin: 0px auto;
      width: 110rem;
      text-align: left;
      padding-top: 30px;
    }
    .bindMobile {
      height: auto;
      margin: 0 auto;
      position: relative;
      .step-one {
        position: absolute;
        top: 2rem;
        left: 0rem;
        width: 6rem;
        height: 5.2rem;
        border: 0.1rem solid rgb(105, 241, 189);
        border-radius: 50%;
        font-size: 3rem;
        padding-top: 0.8rem;
        background-color: rgb(105, 241, 189);
        color: #fff;
      }
      .step-link {
        position: absolute;
        top: 6.1rem;
        left: 3rem;
        border-left: 0.1rem solid rgb(105, 241, 189);
        height: 100%;
        min-height: 58.3rem;
        width: 0.1rem;
      }
      .mobile-con {
        margin: 0 auto;
        width: 60rem;
        position: relative;
        font-size: 1.6rem;
        span {
          width: 15rem;
          text-align: right;
        }
        .input-line {
          display: flex;
          padding: 1rem 0px;
          line-height: 4rem;
          .el-input {
            width: 25rem;
          }
        }
        .mobile-c {
          text-align: left;
          // margin-top: 5vh;
          padding-top: 50px;
          .el-input {
            display: inline-block;
            width: 27rem;
          }
          .el-button {
            margin-left: 1.5rem;
          }
        }
        .mobileback {
          text-align: left;
          margin-top: 5vh;
          .el-input {
            display: inline-block;
            width: 27rem;
          }
        }
        .step {
          margin-right: 2rem;
          text-align: right;
          .el-button {
            margin-top: 2.5rem;
            margin-right: 2rem;
          }
        }
      }
    }
    .register-con {
      width: 110rem;
      margin: 0 auto;
      text-align: left;
      .input-line {
        margin: 0 20px 0 50px;
        height: 50px;
        line-height: 50px;
        span {
          display: inline-block;
          width: 100px;
          text-align: left;
          font-size: 16px;
          line-height: 30px;
        }
        .el-input {
          width: 204px;
        }
        .el-textarea {
          width: 84rem;
        }
        i {
          font-style: normal;
          font-size: 16px;
          margin-left: 10px;
          line-height: 30px;
        }
      }
      .code {
        margin: 0 20px 0 50px;
        display: flex;
        span {
          display: inline-block;
          width: 100px;
          text-align: left;
          font-size: 16px;
          line-height: 30px;
        }
        .el-input {
          width: 204px;
        }
        i {
          font-style: normal;
          font-size: 16px;
          margin-left: 10px;
        }
      }
      .iphonpe {
        margin: 0 20px 0 50px;
        span {
          display: inline-block;
          width: 100px;
          text-align: left;
          font-size: 16px;
          line-height: 40px;
        }
        .el-input {
          width: 204px;
        }
        .el-button {
          margin-left: 10px;
        }
        i {
          font-style: normal;
          font-size: 16px;
          margin-left: 10px;
        }
      }
      .mobile-yan {
        margin: 0 20px 0 50px;
        span {
          display: inline-block;
          width: 100px;
          text-align: left;
          font-size: 16px;
          line-height: 30px;
        }
        .el-input {
          width: 204px;
        }
        i {
          font-style: normal;
        }
      }
      .note {
        margin: 0 20px 0 50px;
        span {
          display: inline-block;
          width: 100px;
          text-align: left;
          font-size: 16px;
          line-height: 30px;
        }
        .el-textarea {
          width: 84rem;
        }
      }
      .btnsure {
        position: relative;
        height: 40px;
        .el-button {
          position: absolute;
          left: 150px;
          top: 0px;
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 0.1rem dashed #d9d9d9;
  border-radius: 0.6rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
</style>
