<template>
  <div class="cut-base">
    <div class="close el-icon-circle-close" @click="onclose"></div>
    <div
      :style="{ width: baseWidth + 'px', height: baseHeight + 'px' }"
      class="cutRange"
    >
      <canvas
        class="cut"
        ref="cutboard"
        :width="baseWidth"
        :height="baseHeight"
        @mousedown="canvasMouseDown($event)"
        @mousemove="canvasMouseMove($event)"
        @mouseup="canvasMouseEnd($event)"
        @mouseleave="canvasMouseEnd()"
      />
    </div>
    <div class="btn">
      <el-button @click="onSureCut">确认剪切</el-button>
    </div>
  </div>
</template>

<script>
var baseWidth;
var baseHeight;
var imageHeight;
var imageWidth;
var wmHeight;
var wmWidth;
var imageX1, imageY1, imageX2, imageY2;
var selectX1, selectY1, selectX2, selectY2;
var lineWidth;
var status = "0";
var lastDownX;
var lastDownY;
var lastDownSelect = {};
/* 
1 2 3
4 5 6
7 8 9
*/

export default {
  name: "Cut",
  props: ["index", "param", "type", "idCardInfo"],
  components: {},
  data() {
    return {
      baseWidth: 0,
      baseHeight: 0,
      baseSrc: "",
      wmSrc: "",
      canvas: "",
      image: undefined,
      wmimage: undefined,
      closeflag: 1,
      cutType: 1,
    };
  },
  created() {
    console.log("cut created", this.idCardInfo);
    if (this.idCardInfo.type == "front") {
      this.cutType = 1;
    } else {
      this.cutType = 2;
    }
    baseWidth = parseInt(this.idCardInfo.width);
    baseHeight = parseInt(this.idCardInfo.height);
    this.baseWidth = parseInt(this.idCardInfo.width);
    this.baseHeight = parseInt(this.idCardInfo.height);
    this.baseSrc = this.idCardInfo.src;
    // this.wmSrc = this.param.wmsrc;
    this.image = new Image();
    this.image.onload = () => {
      imageHeight = this.image.height;
      imageWidth = this.image.width;
      //   var imageWM = new Image();
      //   imageWM.onload = () => {
      //     wmHeight = imageWM.height;
      //     wmWidth = imageWM.width;
      //     this.init();
      //   };
      //   imageWM.src = this.wmSrc;
      this.init();
    };
    this.image.src = this.baseSrc;
  },
  mounted() {
    this.canvas = this.$refs.cutboard;
  },
  methods: {
    // 确认剪切
    onSureCut() {
      var x1 = (selectX1 - imageX1) / (imageX2 - imageX1);
      var y1 = (selectY1 - imageY1) / (imageY2 - imageY1);
      var x2 = (selectX2 - imageX1) / (imageX2 - imageX1);
      var y2 = (selectY2 - imageY1) / (imageY2 - imageY1);

      this.closeflag = 2;

      var arrsCard = {};
      arrsCard.x1 = x1;
      arrsCard.x2 = x2;
      arrsCard.y1 = y1;
      arrsCard.y2 = y2;

      this.$emit("editFinishedCard", this.closeflag, arrsCard, this.cutType);
    },
    canvasMouseEnd() {
      status = 0;
    },
    canvasMouseDown(event) {
      var gap =
        imageX2 - imageX1 > imageY2 - imageY1
          ? (imageY2 - imageY1) / 10
          : (imageX2 - imageX1) / 10;
      var x = event.layerX || event.offsetX;
      var y = event.layerY || event.offsetY;
      if (this.testPoint(selectX1, selectY1, x, y, gap)) {
        console.log("down at 1");
        status = 1;
      } else if (this.testPoint(selectX2, selectY1, x, y, gap)) {
        console.log("down at 3");
        status = 3;
      } else if (this.testPoint(selectX1, selectY2, x, y, gap)) {
        console.log("down at 7");
        status = 7;
      } else if (this.testPoint(selectX2, selectY2, x, y, gap)) {
        console.log("down at 9");
        status = 9;
      } else if (
        this.testPoint((selectX1 + selectX2) / 2, selectY1, x, y, gap)
      ) {
        console.log("down at 2");
        status = 2;
      } else if (
        this.testPoint(selectX1, (selectY1 + selectY2) / 2, x, y, gap)
      ) {
        console.log("down at 4");
        status = 4;
      } else if (
        this.testPoint(selectX2, (selectY1 + selectY2) / 2, x, y, gap)
      ) {
        console.log("down at 6");
        status = 6;
      } else if (
        this.testPoint((selectX1 + selectX2) / 2, selectY2, x, y, gap)
      ) {
        console.log("down at 8");
        status = 8;
      } else if (
        this.testPoint(
          (selectX1 + selectX2) / 2,
          (selectY1 + selectY2) / 2,
          x,
          y,
          gap * 4
        )
      ) {
        console.log("down at 5");
        status = 5;
      } else {
        return;
      }
      lastDownX = x;
      lastDownY = y;
      lastDownSelect.selectX1 = selectX1;
      lastDownSelect.selectY1 = selectY1;
      lastDownSelect.selectX2 = selectX2;
      lastDownSelect.selectY2 = selectY2;
    },
    canvasMouseMove(event) {
      if (status == 0) {
        return;
      }
      var x = event.layerX || event.offsetX;
      var y = event.layerY || event.offsetY;
      var relativeX = x - lastDownX;
      var relativeY = y - lastDownY;
      var newSelect = {
        selectX1: lastDownSelect.selectX1,
        selectX2: lastDownSelect.selectX2,
        selectY1: lastDownSelect.selectY1,
        selectY2: lastDownSelect.selectY2,
      };
      switch (status) {
        case 1:
          newSelect.selectX1 = lastDownSelect.selectX1 + relativeX;
          newSelect.selectY1 = lastDownSelect.selectY1 + relativeY;
          break;
        case 3:
          newSelect.selectX2 = lastDownSelect.selectX2 + relativeX;
          newSelect.selectY1 = lastDownSelect.selectY1 + relativeY;
          break;
        case 7:
          newSelect.selectX1 = lastDownSelect.selectX1 + relativeX;
          newSelect.selectY2 = lastDownSelect.selectY2 + relativeY;
          break;
        case 9:
          newSelect.selectX2 = lastDownSelect.selectX2 + relativeX;
          newSelect.selectY2 = lastDownSelect.selectY2 + relativeY;
          break;
        case 2:
          newSelect.selectY1 = lastDownSelect.selectY1 + relativeY;
          break;
        case 4:
          newSelect.selectX1 = lastDownSelect.selectX1 + relativeX;
          break;
        case 6:
          newSelect.selectX2 = lastDownSelect.selectX2 + relativeX;
          break;
        case 8:
          newSelect.selectY2 = lastDownSelect.selectY2 + relativeY;
          break;
        case 5:
          newSelect.selectX1 = lastDownSelect.selectX1 + relativeX;
          newSelect.selectX2 = lastDownSelect.selectX2 + relativeX;
          newSelect.selectY1 = lastDownSelect.selectY1 + relativeY;
          newSelect.selectY2 = lastDownSelect.selectY2 + relativeY;
          break;
      }
      if (newSelect.selectX1 < imageX1) {
        newSelect.selectX1 = imageX1;
      }
      if (newSelect.selectX2 > imageX2) {
        newSelect.selectX2 = imageX2;
      }

      if (newSelect.selectY1 < imageY1) {
        newSelect.selectY1 = imageY1;
      }
      if (newSelect.selectY2 > imageY2) {
        newSelect.selectY2 = imageY2;
      }
      if (newSelect.selectX2 - newSelect.selectX1 < (imageX2 - imageX1) / 10) {
        return;
      }
      if (newSelect.selectY2 - newSelect.selectY1 < (imageY2 - imageY1) / 10) {
        return;
      }
      selectX1 = newSelect.selectX1;
      selectX2 = newSelect.selectX2;
      selectY1 = newSelect.selectY1;
      selectY2 = newSelect.selectY2;
      this.drawAll();
    },
    testPoint(targetX, targetY, testX, testY, distance) {
      return (
        distance >
        Math.pow(
          Math.pow(targetX - testX, 2) + Math.pow(targetY - testY, 2),
          0.5
        )
      );
    },
    init() {
      console.log(wmHeight, wmWidth);
      var baseWTH = baseWidth / baseHeight;
      var imageWTH = imageWidth / imageHeight;
      if (baseWTH > imageWTH) {
        // space in left and right
        var radio = baseHeight / imageHeight;
        imageX1 = (baseWidth - imageWidth * radio) / 2;
        imageX2 = baseWidth - imageX1;
        imageY1 = 0;
        imageY2 = baseHeight;
      } else {
        //space in top and bottom
        var radio2 = baseWidth / imageWidth;
        imageX1 = 0;
        imageX2 = baseWidth;
        imageY1 = (baseHeight - imageHeight * radio2) / 2;
        imageY2 = baseHeight - imageY1;
      }
      selectX1 = imageX1 + (imageX2 - imageX1) * 0.1;
      selectX2 = imageX2 - (imageX2 - imageX1) * 0.1;
      selectY1 = imageY1 + (imageY2 - imageY1) * 0.1;
      selectY2 = imageY2 - (imageY2 - imageY1) * 0.1;
      lineWidth = (imageX2 - imageX1) * 0.005;
      this.drawAll();
    },
    drawAll() {
      var ctx = this.canvas.getContext("2d");
      ctx.fillStyle = "#000000";
      ctx.fillRect(0, 0, baseWidth, baseHeight);
      ctx.drawImage(
        this.image,
        0,
        0,
        imageWidth,
        imageHeight,
        imageX1,
        imageY1,
        imageX2 - imageX1,
        imageY2 - imageY1
      );
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(0, baseHeight);
      ctx.lineTo(baseWidth, baseHeight);
      ctx.lineTo(baseWidth, 0);
      ctx.lineTo(selectX2, 0);
      ctx.lineTo(selectX2, selectY2);
      ctx.lineTo(selectX1, selectY2);
      ctx.lineTo(selectX1, selectY1);
      ctx.lineTo(selectX2, selectY1);
      ctx.lineTo(selectX2, 0);
      ctx.lineTo(0, 0);
      ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
      ctx.fill();
      // ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
      // ctx.fillRect(0, 0, baseWidth, selectY1);
      // ctx.fillRect(0, selectY2, baseWidth, baseHeight);
      // ctx.fillRect(0, selectY1 - 1, selectX1, selectY2 - selectY1 + 2);
      // ctx.fillRect(selectX2, selectY1, selectX1, selectY2 - selectY1);
      ctx.strokeStyle = "rgba(56,80,236,0.5)";
      ctx.lineWidth = lineWidth * 0.4;
      ctx.beginPath();
      ctx.moveTo(selectX1, selectY1);
      ctx.lineTo(selectX1, selectY2);
      ctx.lineTo(selectX2, selectY2);
      ctx.lineTo(selectX2, selectY1);
      ctx.closePath();
      ctx.stroke();
      ctx.beginPath();
      ctx.strokeStyle = "rgb(56,80,236)";
      ctx.lineWidth = lineWidth * 2;
      var gap = lineWidth * 5;
      ctx.moveTo(selectX1 + gap, selectY1);
      ctx.lineTo(selectX1, selectY1);
      ctx.lineTo(selectX1, selectY1 + gap);
      ctx.moveTo(selectX1, (selectY2 + selectY1) / 2 - gap);
      ctx.lineTo(selectX1, (selectY2 + selectY1) / 2 + gap);
      ctx.moveTo(selectX1, selectY2 - gap);
      ctx.lineTo(selectX1, selectY2);
      ctx.lineTo(selectX1 + gap, selectY2);
      ctx.moveTo((selectX2 + selectX1) / 2 - gap, selectY2);
      ctx.lineTo((selectX2 + selectX1) / 2 + gap, selectY2);
      ctx.moveTo(selectX2 - gap, selectY2);
      ctx.lineTo(selectX2, selectY2);
      ctx.lineTo(selectX2, selectY2 - gap);
      ctx.moveTo(selectX2, (selectY2 + selectY1) / 2 + gap);
      ctx.lineTo(selectX2, (selectY2 + selectY1) / 2 - gap);
      ctx.moveTo(selectX2, selectY1 + gap);
      ctx.lineTo(selectX2, selectY1);
      ctx.lineTo(selectX2 - gap, selectY1);
      ctx.moveTo((selectX2 + selectX1) / 2 + gap, selectY1);
      ctx.lineTo((selectX2 + selectX1) / 2 - gap, selectY1);
      ctx.stroke();
    },
    onclose() {
      this.closeflag = 2;
      this.$emit("editFinishedCard", this.closeflag);
    },
  },
};
</script>

<style lang="less">
.close {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 5rem;
  color: #fff !important;
}
.cut-base {
  position: relative;
}

.cutRange {
  width: 120rem;
  margin: 0 auto;
  position: absolute;
  top: 24%;
  left: 30%;
}

.btn {
  height: 8rem;
  position: absolute;
  top: 78%;
  left: 50%;
}
</style>
